/*
 * Copyright (C) 2023-2024 akquinet GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and limitations under the License.
 */

// based on gemSpec Perf, PTV 1.1.2-0
import { fetchUtils } from "react-admin";

const osVersion = navigator.userAgentData?.platform ?? navigator.platform;
const clientId = "GEMakquiTIMhaf3Q2ssR"; //set by idp registration

export const rawDataHeader = [clientId, osVersion].join(", ");

export function fetchJsonWithRawDataHeader(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers();
  }

  options.headers.set("X-TIM-User-Agent", rawDataHeader);

  return fetchUtils.fetchJson(url, options);
}
